import React, { useState } from "react";
import SBMTbtn from "../../Atoms/SBMTbtn/SBMTbtn";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "./Loader";

function Contacts() {
	const slug = useParams().slug;
	const { t } = useTranslation();
	const [policy, setPolicy] = useState(false);
	const [send, setSend] = useState(false);
	const [data, setData] = useState({
		name: "",
		lastname: "",
		tel: "",
		mail: "",
		paese: "",
		provincia: "",
		citta: "",
		cap: "",
		msg: "",
		type: slug ? slug : "pagina contatti",
		page: slug ? slug : "pagina contatti",
	});

	//console.log(slug);

	const sendMail = async () => {
		//console.log(data);
		setSend(true);
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};
		const url = "https://us-central1-webinal.cloudfunctions.net/app/api/mail/sardi";

		await axios.post(url, data, { headers }).then((res) => {
			console.log(res);
			if (!res.error) {
				window.alert("Messaggio inviato con successo");
        setSend(false);
			} else {
				window.alert("Errore nell'invio del messaggio");
			}
		});
	};
	return (
		<div>
			<h3 className="font-bold text-3xl text-center mt-16">{t("_info")}</h3>
			{send ? (
				<div className="w-full flex justify-center h-[60vh] items-center"> 
          <Loader />
        </div>
			) : (
				<div>
					<div className="w-[80vw] grid grid-cols-1 gap-6 mt-9 ml-[50%] translate-x-[-50%]">
						<div className="grid grid-cols-1 gap-10 mt-9">
							<div className="grid md:grid-cols-2 gap-10 ">
								<input
									value={data.name}
									onChange={(e) => {
										setData({ ...data, name: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px] focus:border-primary focus:border"
									type="text"
									placeholder={t("_nome") + "*"}
								/>
								<input
									value={data.lastname}
									onChange={(e) => {
										setData({ ...data, lastname: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="text"
									placeholder={t("_cognome") + "*"}
								/>
								<input
									value={data.tel}
									onChange={(e) => {
										setData({ ...data, tel: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="tel"
									placeholder={t("_telefono")}
									name=""
									id=""
								/>
								<input
									value={data.mail}
									onChange={(e) => {
										setData({ ...data, mail: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="email"
									placeholder={t("_email") + "*"}
									name=""
									id=""
								/>
								<input
									value={data.paese}
									onChange={(e) => {
										setData({ ...data, paese: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="text"
									placeholder={t("_paese", +"*")}
								/>
								<input
									value={data.provincia}
									onChange={(e) => {
										setData({ ...data, provincia: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="text"
									placeholder={t("_provincia")}
								/>
								<input
									value={data.citta}
									onChange={(e) => {
										setData({ ...data, citta: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="text"
									placeholder={t("_città") + "*"}
								/>
								<input
									value={data.cap}
									onChange={(e) => {
										setData({ ...data, cap: e.target.value });
									}}
									className="bg-cultured p-2 pl-4 rounded-[7px]"
									type="text"
									placeholder={t("_cap") + "*"}
								/>
							</div>
							<input
								value={data.msg}
								onChange={(e) => {
									setData({ ...data, msg: e.target.value });
								}}
								className="bg-cultured pt-2 pb-12 pl-4 rounded-[7px]"
								type="text"
								placeholder={t("_messaggio") + "*"}
							/>
						</div>
						<div>
							<p className="mt-2 font-medium">Privacy*</p>
							<div className="my-2 flex items-center">
								<input
									className="my-3 scale-125 border-blue-700 border-solid border-2"
									type="checkbox"
									name=""
									id=""
									onChange={() => {
										setPolicy(!policy);
										console.log(policy);
									}}
								/>
								<p className="ml-3">
									{t("_priv_acc")}{" "}
									<a className="underline" href="/privacy">
										Privacy Policy
									</a>
								</p>
							</div>
							<div className="translate-x-[-10px]">
								<button
									className="h-12 px-5 bg-gainsboro text-black font-bold rounded-full hover:bg-black hover:text-white"
									onClick={() => {
										policy && sendMail();
									}}
								>
									<p>{t("_invia")}</p>
								</button>
								<p>
								Controlla se quello che stai cercando sia nelle nostre <a className="underline" href="/faq">FAQ</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Contacts;
